.container_card{
    display: grid;
    grid-template-columns: repeat(4, minmax(100px, 1fr));
    gap: 20px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 1rem 2rem;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
    border: 1px solid var(--neutral-grey-100);
}


.card-title{
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--primary-black);
    text-align: center;
}

.card-value{
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary-yellow);
}

.main__content__cards{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    background: var(--secondary-white, #FFFFFF);
    border-radius: 20px;
    width: 100%;
    padding: 2rem 2rem;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
}

.main__content__cards__item{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.main__content__cards__item a{
    cursor: pointerpinte;
}
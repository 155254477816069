.main-container-table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(1, auto);
    gap: 1rem;
}

.event-section {
    align-items: flex-start;
    background: var(--secondary-white);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: auto;
}

.event-title {
    font-size: 24px;
    margin-bottom: 0px;
    color: #333;
}

.event-description {
    font-size: 16px;
    margin-bottom: 20px;
    color: #666;
}

.table-container {
    overflow: visible;
    border-radius: 12px;
}

.styled-table {
    width: 100%;
    border-collapse: separate; /* Use 'separate' to respect border-radius */
    border-spacing: 0; /* Prevent gaps between cells */
    margin: 5px 0 25px 0;
    font-size: 14px;
    text-align: left;
    border-radius: 12px; /* Apply rounded corners to the table */
    overflow: hidden; /* Clip child elements to rounded corners */
    border: 1px solid #ddd; /* Adds a border to the entire table */
}

/* Ensure header rows are styled with proper rounded corners */
.styled-table thead {
    background-color: var(--neutral-grey-50);
}

.styled-table thead tr {
    height: 40px;
}

.styled-table th {
    padding: 10px 20px;
    text-align: left;
    font-weight: bold;
    color: #333;
    border-bottom: 1px solid var(--neutral-grey-200);
}

/* Apply border-radius to the first and last th to respect the rounded corners */
.styled-table th:first-child {
    border-top-left-radius: 12px;
}

.styled-table th:last-child {
    border-top-right-radius: 12px;
}

.styled-table tbody tr {
    height: 50px;
    border-bottom: 1px solid #dddddd;
}

/* Round bottom corners for the last row in tbody */
.styled-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 12px;
}

.styled-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 12px;
}

.styled-table td {
    padding: 10px 20px;
    color: #666;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #fafafa;
}

.styled-table tbody tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

.styled-table th:last-of-type,
.styled-table td:last-of-type {
    border-right: none;
}

.no-data-text {
    font-size: 14px;
    color: #888;
    text-align: center;
    padding: 10px;
}

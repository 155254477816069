.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    gap: 50px;
    background-color: var(--primary-white);

    padding: 2rem;
}


.main__header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 2rem;
    background-color: var(--secondary-white);
    width: 100%;

    border-radius: 20px;


    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
}

.main__header img{
    width: 170px;
}

.main__header H1{
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary-black);
    margin-right: 10rem;
    width: 100%;
    text-align: center;
}


.main__content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    width: 100%;
    height: 100%;
}